import Link from "next/link"
import { rem } from "polished"
import React, { useState } from "react"
import styled from "styled-components"
import { theme } from "@aistihealth/web-common/src/util/styles/theme"
import { ArrowIcon } from "@aistihealth/web-common/src/components/icon/ArrowIcon"
import { NavigationItemLevel } from "../../util/typing/navigation"
import { BaseNavigationLinkStyles } from "./BaseLink"
import { SubMenu } from "./SubMenu"

export const halfNavigationLinkSpacingPx = 16
export interface NavigationLinkProps {
  className?: string
  href: string
  isActive?: (href: string) => boolean
  parentIsActive: boolean
  subItems?: NavigationItemLevel[]
}

interface ContainerProps {
  active: boolean
}

const ButtonWrapper = styled.button`
  display: inline-flex;
  background-color: inherit;
  color: inherit;
  font: inherit;
  padding: inherit;
  border: none;
  cursor: pointer;
  gap: ${theme.spacings.small};
`

export const Container = styled.li<ContainerProps>`
  ${BaseNavigationLinkStyles};

  // Mobile-specific styles
  margin-top: ${theme.spacings.medium};
  margin-bottom: ${theme.spacings.medium};
  padding-top: ${theme.spacings.small};
  padding-bottom: ${theme.spacings.small};

  button {
    padding: 0;
  }

  // Desktop-specific styles
  ${theme.breakpoints.md} {
    > a,
    > button {
      padding: ${() =>
        `${rem(halfNavigationLinkSpacingPx / 2)} ${rem(
          halfNavigationLinkSpacingPx,
        )}`};
    }
    > button {
      margin: 0;
    }
    margin-top: 0;
    margin-bottom: ${theme.spacings.small};
    padding-top: 0;
    padding-bottom: 0;
  }
`
const StyledArrowIcon = styled(ArrowIcon)`
  ${theme.textStyle("default", "medium", "bold")}

  // Desktop-specific styles
  ${theme.breakpoints.md} {
    transform: none;
  }
`

export const NavigationLink: React.FC<NavigationLinkProps> = ({
  children,
  href,
  isActive,
  parentIsActive,
  subItems,
  className,
}) => {
  const [subListOpen, setSubListOpen] = useState<boolean>(false)
  return (
    <>
      {subItems ? (
        <Container active={parentIsActive} className={className}>
          <ButtonWrapper onClick={() => setSubListOpen(!subListOpen)}>
            {children}
            <StyledArrowIcon
              color={parentIsActive ? "orange" : "primary"}
              direction={subListOpen ? "down" : "right"}
            />
          </ButtonWrapper>
          <SubMenu
            isOpen={subListOpen}
            subItems={subItems}
            isActive={isActive}
          />
        </Container>
      ) : (
        <Container active={parentIsActive} className={className}>
          <Link href={href}>{children}</Link>
        </Container>
      )}
    </>
  )
}
