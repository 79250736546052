import React, { useCallback, useMemo } from "react"
import styled from "styled-components"
import { useRouter } from "next/router"

import {
  BrandSpacing,
  theme,
} from "@aistihealth/web-common/src/util/styles/theme"
import { useAdminAuthz } from "../../authz/AdminAuthzContext"
import { NavigationItem } from "../../util/typing/navigation"
import { NavigationLink } from "./NavigationLink"

export const halfNavigationLinkSpacingPx = 16

interface ContainerProps {
  bottomMargin?: BrandSpacing
}

const Container = styled.nav<ContainerProps>`
  align-self: flex-start;
  flex-grow: 1;
  margin-top: ${theme.spacings.medium};
  width: 100%;

  position: relative;
  background-color: ${theme.colors.white};
  color: ${theme.colors.primary};
  margin-bottom: ${({ bottomMargin }) =>
    bottomMargin && theme.spacings[bottomMargin]};
  overflow: hidden;

  ${theme.breakpoints.md} {
    overflow: initial;
  }
`

const List = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;

  ${theme.breakpoints.md} {
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
  }
`

const ParentLinkContainer = styled(NavigationLink)`
  &:hover {
    ${theme.breakpoints.md} {
      ul {
        visibility: visible;
      }
    }
  }
`

const hasAllowedRole = (
  allowedRoles: string[] | undefined,
  userRoles: string[] | undefined,
): boolean => {
  if (userRoles.includes("superadmin")) {
    return true
  }

  return (
    allowedRoles?.some(allowedRole =>
      userRoles?.find(userRole => userRole === allowedRole),
    ) ?? true
  )
}

export interface NavigationProps {
  className?: string
  items: NavigationItem[]
  screenReaderLabel?: string
}

export const Navigation: React.FC<NavigationProps> = ({
  items,
  screenReaderLabel,
  className,
}) => {
  const router = useRouter()
  const authz = useAdminAuthz()

  const isActive = useCallback(
    (href: string): boolean => {
      if (href === "/") {
        return router.pathname === href
      }

      return (
        router.pathname.startsWith(href) ||
        router.pathname.startsWith(`${href}/`)
      )
    },
    [router],
  )

  const authorizedItems = useMemo((): NavigationItem[] => {
    if (!authz.adminUser) {
      return []
    }

    return items.flatMap(item => {
      const parentIsAuthorized = hasAllowedRole(
        item.allowedGlobalRoles,
        authz.adminUser.globalRoles,
      )

      if (!parentIsAuthorized) {
        return []
      }

      return {
        ...item,
        subItems: item.subItems?.flatMap(subItem => {
          const subItemIsAuthorized = hasAllowedRole(
            subItem.allowedGlobalRoles,
            authz.adminUser.globalRoles,
          )

          if (!subItemIsAuthorized) {
            return []
          }

          return subItem
        }),
      }
    })
  }, [authz.adminUser, items])

  const navigationItems = authorizedItems.map(({ subItems, ...item }) => {
    const parentIsActive = isActive(item.href)

    return (
      <React.Fragment key={item.displayName + item.href}>
        {subItems ? (
          <ParentLinkContainer
            href={item.href}
            isActive={isActive}
            parentIsActive={parentIsActive}
            subItems={subItems}
          >
            {item.displayName}
          </ParentLinkContainer>
        ) : (
          <NavigationLink parentIsActive={parentIsActive} href={item.href}>
            {item.displayName}
          </NavigationLink>
        )}
      </React.Fragment>
    )
  })

  return (
    <Container
      aria-label={screenReaderLabel}
      className={className}
      bottomMargin="medium"
    >
      <List>{navigationItems}</List>
    </Container>
  )
}
