import { Client } from "@datocms/cma-client"
import { Item, Role } from "@datocms/cma-client/src/generated/SimpleSchemaTypes"

import { SiteLocale } from "./gql/generated/graphql"

export const localeToDatoLocale = (locale: string): SiteLocale => {
  switch (locale) {
    case "fi":
      return SiteLocale.fi
    case "sv":
      return SiteLocale.sv
    case "en":
    default:
      return SiteLocale.en
  }
}

export interface GenerateMetaTagsParams {
  description?: string
  title?: string
}

/**
 * Generates DatoCMS-like metatags. Should not be needed when all pages are in CMS.
 *
 * @param title
 * @param description
 */
export const generateMetaTags = ({
  title,
  description,
}: GenerateMetaTagsParams) => {
  return [
    ...(title
      ? [
          {
            tag: "title",
            attributes: null,
            content: title,
          },
          {
            tag: "meta",
            attributes: {
              property: "og:title",
              content: title,
            },
            content: null,
          },
          {
            tag: "meta",
            attributes: {
              name: "twitter:title",
              content: title,
            },
            content: null,
          },
        ]
      : []),
    ...(description
      ? [
          {
            tag: "meta",
            attributes: {
              name: "description",
              content: description,
            },
            content: null,
          },
          {
            tag: "meta",

            attributes: {
              property: "og:description",
              content: description,
            },
            content: null,
          },
          {
            tag: "meta",
            attributes: {
              name: "twitter:description",
              content: description,
            },
            content: null,
          },
        ]
      : []),
  ]
}

export const fetchRoleByName = async (
  client: Client,
  roleName: string,
): Promise<Role | undefined> => {
  return (await client.roles.list()).find(r => r.name === roleName)
}

export const fetchBrandIconItemByName = async (
  client: Client,
  iconName: string,
): Promise<Item | null> => {
  const iconRecords = await client.items.list({
    filter: {
      fields: {
        name: {
          eq: iconName,
        },
      },
      type: "brand_icon",
    },
  })

  if (iconRecords.length > 0) {
    return iconRecords[0]
  }

  return null
}
