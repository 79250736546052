import React, { FC } from "react"
import {
  Col,
  Grid,
  Row,
  ReactStyledFlexboxgrid,
} from "react-styled-flexboxgrid"
import { TestIdProps } from "../../e2e-tests/utils/locator-utils"
import { gridColumnProps } from "../../util/styles/grid-column-props"

export type ContentLayoutType = "oneColumn" | "twoColumns"

export interface SingleColLayoutProps {
  className?: string
  colProps?: ReactStyledFlexboxgrid.IColProps
  fluid?: boolean
  rowProps?: ReactStyledFlexboxgrid.IRowProps
}

export const SingleColLayout: FC<SingleColLayoutProps & TestIdProps> = ({
  rowProps,
  colProps,
  fluid,
  children,
  className,
  testId,
}) => (
  <Grid className={className} fluid={fluid} data-test-id={testId}>
    <Row {...rowProps}>
      <Col {...colProps} {...gridColumnProps.oneColumn}>
        {children}
      </Col>
    </Row>
  </Grid>
)
