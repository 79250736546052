import { rem } from "polished"
import React from "react"
import styled from "styled-components"
import { theme } from "@aistihealth/web-common/src/util/styles/theme"
import { NavigationItemLevel } from "../../util/typing/navigation"
import { SubLink } from "./SubLink"

const subMenuMinWidth = 180
export interface SubMenuProps {
  isActive: (href: string) => boolean
  isOpen: boolean
  subItems: NavigationItemLevel[]
}

interface ContainerProps {
  isOpen: boolean
}

const Container = styled.ul<ContainerProps>`
  padding: 0;
  background-color: ${theme.colors.white};
  color: ${theme.colors.primary};

  // Mobile-specific styles
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  position: ${({ isOpen }) => (isOpen ? "relative" : "absolute")};

  // Desktop-specific styles
  ${theme.breakpoints.md} {
    min-width: ${rem(subMenuMinWidth)};
    position: absolute;
    visibility: hidden;
  }
`

export const SubMenu: React.FC<SubMenuProps> = ({
  isOpen,
  isActive,
  subItems,
}) => {
  return (
    <Container isOpen={isOpen}>
      {subItems.map(subItem => (
        <SubLink
          key={subItem.displayName + subItem.href}
          href={subItem.href}
          active={isActive(subItem.href)}
        >
          {subItem.displayName}
        </SubLink>
      ))}
    </Container>
  )
}
