import React from "react"
import styled from "styled-components"
import { useRouter } from "next/router"
import { rem } from "polished"
import { LocaleLink, halfLocaleLinkSpacingPx } from "./LocaleLink"

export interface LocaleItem {
  displayName: string
  locale: string
}

const Container = styled.nav``

const List = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style-type: none;
  margin-right: -${rem(halfLocaleLinkSpacingPx)};
  margin: 0;
  padding: 0;
`

export interface LocaleSelectorProps {
  className?: string
  items: LocaleItem[]
  screenReaderLabel: string
}

export const LocaleSelector: React.FC<LocaleSelectorProps> = ({
  className,
  items,
  screenReaderLabel,
}) => {
  const router = useRouter()

  return (
    <Container className={className} aria-label={screenReaderLabel}>
      <List>
        {items.map(({ displayName, locale }) => (
          <LocaleLink
            active={router.locale === locale}
            href={router.asPath}
            key={displayName + locale}
            locale={locale}
          >
            {displayName}
          </LocaleLink>
        ))}
      </List>
    </Container>
  )
}
