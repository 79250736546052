import Link from "next/link"
import React from "react"
import styled from "styled-components"
import { theme } from "@aistihealth/web-common/src/util/styles/theme"
import { BaseNavigationLinkStyles } from "./BaseLink"

interface SubItemProps {
  active?: boolean
  href: string
}

interface ContainerProps {
  active: boolean
}

export const Container = styled.li<ContainerProps>`
  ${BaseNavigationLinkStyles};
  ${theme.textStyle("default", "small", "semibold")}

  // Increase the clickable area
  a {
    display: block;
    padding: ${theme.spacings.medium} ${theme.spacings.large};
  }

  // Mobile-specific styles
  margin: ${theme.spacings.medium} auto;

  // Desktop-specific styles
  ${theme.breakpoints.md} {
    border: 1px solid ${theme.colors.grey};
    margin: auto;
    > a,
    > button {
      padding: calc(${theme.spacings.xsmall} + ${theme.spacings.small})
        ${theme.spacings.medium};
    }

    &:not(:last-child) {
      border-bottom: none;
    }

    &:hover {
      opacity: 1;
      background-color: ${theme.colors.greyLight};
    }
  }
`

export const SubLink: React.FC<SubItemProps> = ({ active, children, href }) => {
  return (
    <Container active={active}>
      <Link href={href}>{children}</Link>
    </Container>
  )
}
