import { sendDataLayerEvent } from "@aistihealth/web-common/src/gtm/gtm-utils"
import React, { useCallback } from "react"
import styled, { css } from "styled-components"
import Link from "next/link"
import { rem } from "polished"
import { theme } from "@aistihealth/web-common/src/util/styles/theme"

export interface LocaleLinkProps {
  active: boolean
  href: string
  locale: string
}

export const halfLocaleLinkSpacingPx = 8

interface ContainerProps {
  active: boolean
}

export const Container = styled.li<ContainerProps>`
  ${theme.textStyle("default", "small", "semibold")}
  margin-right: ${rem(halfLocaleLinkSpacingPx)};

  a:link,
  a:visited,
  a:active {
    border-radius: 50%;
    color: ${theme.colors.primary};
    display: inline-block;
    letter-spacing: ${rem(0.5)};
    line-height: ${rem(32)};
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    width: ${rem(32)};
  }

  a:hover,
  a:focus {
    background-color: ${theme.colors.greyCold};
  }


  ${({ active }) =>
    active &&
    css`
      a:link,
      a:visited,
      a:active,
      a:hover,
      a:focus {
        background-color: ${theme.colors.orange};
        color: ${theme.colors.white};
      }
    `}
`

export const LocaleLink: React.FC<LocaleLinkProps> = ({
  active,
  children,
  href,
  locale,
}) => {
  const handleClick = useCallback(() => {
    sendDataLayerEvent({ event: "changeLanguage", language: locale })
  }, [locale])

  return (
    <Container active={active}>
      <Link href={href} locale={locale}>
        <a href={href} onClick={handleClick}>
          {children}
        </a>
      </Link>
    </Container>
  )
}
