import { rem, transparentize } from "polished"
import { css } from "styled-components"
import { theme } from "@aistihealth/web-common/src/util/styles/theme"

interface BaseNavigationLinkProps {
  active?: boolean
}

export const BaseNavigationLinkStyles = css<BaseNavigationLinkProps>`
  ${theme.textStyle("default", "medium", "semibold")}
  list-style-type: none;
  text-align: center;

  > a,
  > button {
    letter-spacing: ${rem(0.5)};
    white-space: nowrap;
    text-transform: uppercase;
    text-decoration: none;
    color: ${theme.colors.primary};
  }

  ${({ active }) =>
    active &&
    css`
      > a,
      > button {
        color: ${theme.colors.orange};
      }
    `}

  &:hover,
  &:focus {
    > a,
    > button {
      color: ${transparentize(0.5, theme.colors.primary)};
    }

    cursor: pointer;
  }
`
