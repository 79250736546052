import { ReactStyledFlexboxgrid } from "react-styled-flexboxgrid"

export type GridColumnProps = {
  oneColumn: ReactStyledFlexboxgrid.IColProps
  twoColumns: ReactStyledFlexboxgrid.IColProps
}

export const gridColumnProps = {
  oneColumn: {
    xs: 12,
  },
  twoColumns: {
    xs: 12,
    md: 6,
  },
}
