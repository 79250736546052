import { BrandIcon } from "@aistihealth/web-common/src/util/styles/theme"
import { LinkButtonProps } from "@aistihealth/web-common/src/components/button/Button"

export const defaultIfString = <T, D>(data: T, defaultValue: D): T | D => {
  if (typeof data === "string") {
    return defaultValue
  }

  return data
}

export interface CardI18nProps {
  description: string
  icon: BrandIcon
  title: string
}

export type ButtonI18nProps = Pick<LinkButtonProps, "href" | "variant"> & {
  text: string
}
