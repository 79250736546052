import styled from "styled-components"
import {
  BrandColor,
  HeadingTextSize,
  HeadingTextWeight,
  TextAlign,
  theme,
} from "../../util/styles/theme"

export interface HeadingProps {
  align?: TextAlign
  color?: BrandColor
  size?: HeadingTextSize
  topMargin?: boolean
  weight?: HeadingTextWeight
}

export const Heading = styled.h1<HeadingProps>`
  ${({ size = "medium", weight = "medium" }) =>
    theme.textStyle("heading", size, weight)}
  color: ${({ color = "primary" }) => theme.colors[color]};
  text-align: ${({ align }) => align};
  margin-top: ${({ topMargin = true }) => !topMargin && "0"};
`
Heading.defaultProps = {
  color: "primary",
  size: "medium",
  topMargin: true,
  weight: "medium",
}
