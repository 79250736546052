import React, { useMemo } from "react"
import Head from "next/head"
import { renderMetaTags } from "react-datocms"
import { TitleMetaLinkTag } from "react-datocms/src/Seo/types"
import styled from "styled-components"
import { useRouter } from "next/router"

import { Tag } from "@aistihealth/web-common/src/datocms/gql/generated/graphql"
import {
  BrandColor,
  theme,
} from "@aistihealth/web-common/src/util/styles/theme"

export interface BaseLayoutProps {
  bottomBackground?: BrandColor
  footerComponent?: React.ReactNode
  headerComponent?: React.ReactNode
  heroComponent?: React.ReactNode
  metaTags?: Array<Tag>
  titleSuffix?: string
  topBackground?: BrandColor
}

type ContentAreaProps = Pick<
  BaseLayoutProps,
  "bottomBackground" | "topBackground"
>

const ContentArea = styled.div<ContentAreaProps>`
  ${props =>
    props.topBackground &&
    props.bottomBackground &&
    `background: linear-gradient(
    180deg,
    ${theme.colors[props.topBackground]} 50%,
    ${theme.colors[props.bottomBackground]} 50%
  );`}

  padding-top: ${theme.spacings.xlarge};
  padding-bottom: ${theme.spacings.xlarge};

  position: relative;
`

const fixTags = (
  tags: TitleMetaLinkTag[],
  titleSuffix: string,
): TitleMetaLinkTag[] => {
  // Add the titleSuffix to the og:title and twitter:title meta tags as DatoCMS does not add it

  return tags.map(t => {
    if (t.tag === "meta") {
      if (
        t.attributes.property === "og:title" ||
        t.attributes.name === "twitter:title"
      ) {
        return {
          ...t,
          attributes: {
            ...t.attributes,
            content: t.attributes.content + titleSuffix,
          },
        }
      }
    }

    return t
  })
}

export const BaseLayout: React.FunctionComponent<BaseLayoutProps> = ({
  children,
  heroComponent,
  topBackground,
  bottomBackground,
  footerComponent,
  headerComponent,
  metaTags,
  titleSuffix,
}) => {
  const router = useRouter()

  const appUrl = process.env.NEXT_PUBLIC_VERCEL_URL
  const pageUrl = appUrl && `https://${appUrl}${router.pathname}`

  const fixedTags = useMemo(() => {
    if (titleSuffix) {
      return fixTags(metaTags, titleSuffix)
    }

    return metaTags
  }, [metaTags, titleSuffix])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          key="viewport"
          content="width=device-width,initial-scale=1.0,minimum-scale=1.0"
        />
        {renderMetaTags(fixedTags)}

        {pageUrl && <meta property="og:url" key="og:url" content={pageUrl} />}

        <link rel="shortcut icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon-aistii-180.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/favicon-aistii-152.png"
        />
        <link rel="icon" sizes="192x192" href="/favicon-aistii-192.png" />
        <link rel="icon" sizes="96x96" href="/favicon-aistii-96.png" />
        <link rel="icon" sizes="32x32" href="/favicon-aistii-32.png" />
        <link rel="icon" sizes="16x16" href="/favicon-aistii-16.png" />
        {process.env.NEXT_PUBLIC_VERCEL_ENV !== "production" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Head>
      {headerComponent}
      {heroComponent}
      <ContentArea
        topBackground={topBackground}
        bottomBackground={bottomBackground}
      >
        {children}
      </ContentArea>
      {footerComponent}
    </>
  )
}
