import { css } from "styled-components"
import { theme } from "../../util/styles/theme"
import { assertNever } from "../../typings/typeUtils"
import { Icon } from "./Icon"
import { styledWithAttrs } from "../../util/styles/utils"

export interface ArrowIconProps {
  direction: "up" | "right" | "down"
  inButton?: boolean
}

export const ArrowIcon = styledWithAttrs(Icon)({
  icon: "arrowDown",
})<ArrowIconProps>`
  ${theme.textStyle("default", "xlarge", "normal")}
  margin-left: ${({ inButton }) => (inButton ? theme.spacings.small : 0)};

  ${theme.breakpoints.md} {
    display: inline;
  }

  transition: transform 0.3s;

  ${({ direction }) => {
    switch (direction) {
      case "up":
        return css`
          transform: rotate(-0.5turn);
        `
      case "right":
        return css`
          transform: rotate(-0.25turn);
        `
      case "down":
        return undefined
      default:
        return assertNever(direction)
    }
  }}
`
