import Link from "next/link"
import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import Image from "next/image"
import { rem } from "polished"
import { theme } from "@aistihealth/web-common/src/util/styles/theme"
import { gridConfig } from "@aistihealth/web-common/src/util/styles/flexbox-config"
import { Button } from "@aistihealth/web-common/src/components/button/Button"
import { Navigation } from "./Navigation"
import { LocaleSelector, LocaleItem } from "./LocaleSelector"
import { NavigationItem } from "../../util/typing/navigation"

import logoImage from '../../../public/aistii-logo-primary.svg'

// TODO: Find out why Prettier can't parse these through the ESLint plugin, even though Prettier itself does support
// this syntax.
/* eslint-disable prettier/prettier */
export type { NavigationItem } from "../../util/typing/navigation"
export type { LocaleItem } from "./LocaleSelector"
/* eslint-enable prettier/prettier */

const headerHeightInMobile = rem(80)
// Value greater than the menu ever will be (but not too much greater as it will effect perceived animation speed)
const menuMaxHeightInMobileRem = rem(750)

const Container = styled.header`
  align-items: center;
  background: ${theme.colors.white};
  display: flex;
  flex-wrap: wrap;

  min-width: ${rem(300)};
  padding: ${theme.spacings.medium};
  position: relative;

  ${theme.breakpoints.md} {
    justify-content: flex-start;
    margin-right: auto;
    margin-left: auto;

    width: ${gridConfig.container.md}rem;
    z-index: 2;
  }

  ${theme.breakpoints.lg} {
    width: ${gridConfig.container.lg}rem;
  }
`

// This makes Next.js's Image behave in a flex context
const ImageContainer = styled.div`
  width: ${rem(93)};
  height: ${rem(30)};
  margin-right: auto;
  position: relative;

  ${theme.breakpoints.md} {
    align-self: flex-start;
    height: ${rem(50)};
    width: ${rem(155)};
  }
`

interface StackProps {
  show: boolean
}

const Stack = styled.div<StackProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  max-height: ${({ show }) => (show ? menuMaxHeightInMobileRem : "0")};
  transition: max-height 0.3s ease;

  ${theme.breakpoints.md} {
    flex-direction: column;
    max-height: none;
  }
`

const StyledNavigation = styled(Navigation)`
  flex-grow: 1;
  margin-top: ${headerHeightInMobile};
  ${theme.breakpoints.md} {
    margin-top: 0;
  }
`

const StyledLocaleSelector = styled(LocaleSelector)<StackProps>`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: ${headerHeightInMobile};
  width: 100%;

  ${theme.breakpoints.md} {
    position: static;
    width: auto;
  }
`

const MenuButton = styled(Button)`
  ${theme.textStyle("default", "xlarge", "normal")}
  font-size: 3rem;

  position: relative;

  border-radius: ${rem(8)};
  padding: 0 ${rem(8)};
  margin-right: -${rem(8)};

  ${theme.breakpoints.md} {
    display: none;
  }
`

export interface HeaderProps {
  authMenu?: React.ReactNode
  localeItems: LocaleItem[]
  logo: {
    href: string
    linkTitle: string
  }
  navigationItems: NavigationItem[]
  onMenuToggle?: (isMenuOpen: boolean) => void
  screenReaderLabels: {
    localeSelector: string
    navigation: string
  }
}

export const Header: React.FC<HeaderProps> = ({
  authMenu,
  localeItems,
  logo,
  navigationItems,
  screenReaderLabels,
  onMenuToggle,
}) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const onMenuClick = useCallback(() => {
    setMenuOpen(prev => !prev)
  }, [])

  useEffect(() => {
    if(onMenuToggle) {
       onMenuToggle(menuOpen)
    }
  }, [menuOpen, onMenuToggle])

  const menuIconName = menuOpen ? "cross" : "hamburgerMenu"

  return (
    <Container>
      <ImageContainer>
        <Link href={logo.href}>
          <a title={logo.linkTitle}>
            <Image
              src={logoImage}
              alt=""
              layout="fill"
              sizes="100%"
              priority
            />
          </a>
        </Link>
      </ImageContainer>
      <StyledLocaleSelector
        show={menuOpen}
        items={localeItems}
        screenReaderLabel={screenReaderLabels.localeSelector}
      />
      {authMenu}
      <MenuButton
        variant="transparent"
        type="button"
        size="small"
        onClick={onMenuClick}
        icon={menuIconName}
      />
      <Stack show={menuOpen}>
        <StyledNavigation
          items={navigationItems}
          screenReaderLabel={screenReaderLabels.navigation}
        />
      </Stack>
    </Container>
  )
}
